// *************************************
//
//   Cell
//   -> Width-limiting blocks
//
// *************************************

.cell {
  margin-left: auto;
  margin-right: auto;
  max-width: $b-maxWidth;
  position: relative; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// ----- Sizes ----- //

.cell--xs {
  max-width: ($b-maxWidth * 0.75); }

.cell--s {
  max-width: ($b-maxWidth * 0.85); }

.cell--l {
  max-width: ($b-maxWidth * 1.5); }
