// *************************************
//
//   Chart
//   -> Narrative chart container
//
// *************************************

.chart {
  // margin-bottom: ($b-space * 0.5)
  // +respond-to($g-m)
 }  //   margin-bottom: ($b-space * 0.75)

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.chart-callout {
  background: darken(white, 4%);
  border-radius: $b-borderRadius;
  font-size: $t-fontSize-xs;
  margin-top: ($b-space * 0.25);
  padding: ($b-space * 0.25);
  width: 100%;
  @include respond-to($g-s) {
    float: right;
    margin-top: $b-space;
    padding: ($b-space * 0.4) ($b-space * 0.5);
    width: 45%; }
  p {
    margin: 0; }
  strong {
    color: $c-gray-dark; } }

.chart-display {
  height: ($b-space * 4);
  margin-left: ($b-space * -0.5);
  overflow: hidden;
  position: relative;
  width: calc(100% + #{$b-space});
  @include respond-to($g-s) {
    height: ($b-space * 5);
    transform: translateY(-10%); }
  @include respond-to($g-m) {
    height: ($b-space * 6); }
  &::after, &::before {
    box-shadow: $b-boxShadow;
    border-radius: 100px;
    bottom: ($b-space * 0.25);
    position: absolute;
    top: ($b-space * 0.125);
    width: $b-space;
    @include respond-to($g-s) {
      content: ''; } }
  &::after {
    left: 100%; }
  &::before {
    right: 100%; }
  p { // REMOVE BEFORE DEPLOY
    margin-top: $b-space; } }

.chart-legend {
  p {
    font-size: $t-fontSize-xs;
    margin: 0;
    &::before {
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: ($b-space * 0.25);
      margin-right: ($b-space * 0.125);
      position: relative;
      top: 1px;
      transition: background 500ms $b-transition;
      width: ($b-space * 0.25); }
    &:last-child::before {
      background: $c-blue; }
    &:first-child::before {
      background: $c-orange; } } }

.chart-legend--blue {
  p:last-child::before {
   background: $c-blue; } }

.chart-statement {
  @include respond-to($g-s) {
    float: left;
    width: 45%; }
  &:last-of-type {
    margin-top: ($b-space * 0.5);
    @include respond-to($g-s) {
      float: right;
      margin-top: 0; } }
  &:only-of-type {
    @include respond-to($g-s) {
      float: left; } }
  .heading {
    font-size: ($t-fontSize-s * 1.125);
    margin-top: 0; }
  .label {
    font-size: $t-fontSize-xxs; } }
