// *************************************
//
//   Bivariate
//   -> Bivariate area charts
//
// *************************************

// .bivariate

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .bivariate-area
//   // fill: rgba(desaturate($c-orange, 5%), 0.5)
//   fill: $c-gray-light
//   transition: fill 500ms $b-transition
//   &:last-of-type
//     fill: $c-navy
//   &:only-of-type
//     fill: $c-orange

.bivariate-area--0 {
  fill: $c-orange;
  .chart--blue & {
    fill: $c-navy; } }
.bivariate-area--1 {
  fill: $c-navy; }

.bivariate-axis {
  pointer-events: none;
  .domain {
    stroke: rgba(white, 0.25) {
      width: 3px; } }
  .tick {
    circle, rect {
      fill: rgba(white, 0.85);
      pointer-events: none !important; }
    line {
      display: none; }
    text {
      alignment-baseline: middle;
      fill: $c-gray-dark;
      font: {
        family: $t-fontFamily;
        size: 6px;
        weight: bold; }
      letter-spacing: 0.125em;
      text-transform: uppercase;
      transform: translateY(1px);
      @include respond-to($g-s) {
        font-size: 8px;
        transform: translateY(0); } } } }

.bivariate-overlay {
  fill: transparent;
  &.is-hovered {
    + .bivariate-guide {
      @include respond-to($g-s) {
        fill: rgba(black, 0.125); } } }
  #narrative-01 &,
  #narrative-02 &,
  #narrative-05 &,
  #narrative-09 &,
  #narrative-10 &,
  #narrative-14 &,
  #narrative-15 & {
    display: none; } }

.bivariate-guide {
  pointer-events: none;
  fill: transparent; }
