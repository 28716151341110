// *************************************
//
//   Label
//   -> Small informational text
//
// *************************************

.label {
  font: {
    size: $t-fontSize-xs;
    weight: 500; }
  letter-spacing: 0.125em;
  margin: 0;
  text-transform: uppercase; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// ----- Theme ----- //

.label--featured {
  background: $c-orange;
  border-radius: 4px;
  color: white;
  display: block;
  float: left;
  font-size: ($t-fontSize-s * 0.625);
  margin-right: ($b-space * 0.125);
  padding: 2px ($b-space * 0.125);
  position: relative;
  top: 4px;
  a:hover & {
    background: $c-blue; } }

.label--serif {
  font-family: $t-fontFamily-heading; }

.label--underlined {
  border-bottom: $b-border;
  margin-bottom: ($b-space * 0.75);
  padding-bottom: ($b-space * 0.125); }

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
