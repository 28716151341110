// *************************************
//
//   Footer
//   -> The end of the narrative
//
// *************************************

.footer {
  font-size: $t-fontSize-s;
  padding: {
    bottom: ($b-space * 0.75);
    top: ($b-space * 0.75); }
  text-align: center;
  @include respond-to($g-s) {
    border-top: $b-border;
    margin-top: $b-space;
    padding: {
      bottom: ($b-space * 1.5);
      top: ($b-space * 1.5); } }
  a:not(.btn) {
    display: inline-block;
    margin-bottom: ($b-space * 0.25); }
  p {
    margin-bottom: ($b-space * 0.25);
    a {
      border-bottom: $b-border; } } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.footer-download,
.footer-promo {
  margin: 0 auto ($b-space * 0.5); }

.footer-download {
  font-size: $t-fontSize-xs;
  margin-bottom: $b-space;
  width: 100%;
  @include respond-to($g-s) {
    float: left;
    width: 50%; } }

.footer-promo {
  align-items: center;
  background: darken(white, 4%);
  border-radius: $b-borderRadius;
  display: flex;
  flex-flow: column nowrap;
  font-size: ($t-fontSize-s * 0.8);
  justify-content: center;
  margin-bottom: $b-space;
  overflow: hidden;
  padding: ($b-space * 0.35) ($b-space * 0.5) ($b-space * 0.5);
  @include respond-to($g-s) {
    flex-flow: row nowrap;
    font-size: inherit;
    padding: ($b-space * 0.35) ($b-space * 0.5); }
  img {
    height: ($b-space * 4);
    margin-bottom: ($b-space * 0.25);
    width: ($b-space * 4);
    @include respond-to($g-s) {
      float: left;
      transform: translateX(7%); } } }

.footer-promo-text {
  @include respond-to($g-s) {
    text-align: left; }
  em {
    color: $c-gray-dark;
    display: block; } }
