// *************************************
//
//   Social
//   -> Sharing icons
//
// *************************************

.social {
  display: inline-block;
  height: ($b-space * 1.5);
  margin: ($b-space * 0.125);
  width: ($b-space * 1.5);
  &:hover, &:focus {
    opacity: 0.5; }
  svg {
    height: auto;
    width: 100%; } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
