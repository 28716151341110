// *************************************
//
//   Filter
//   -> Data explorer interactions
//
// *************************************

.filter {
  background: darken(white, 2.5%);
  border-top: $b-border;
  cursor: pointer;
  flex-flow: column nowrap;
  max-height: ($b-space * 2.5);
  min-height: ($b-space * 2.5);
  padding: ($b-space * 0.75) ($b-space * 0.5);
  position: relative;
  text-align: center;
  transition: all 250ms $b-transition;
  width: 100%;
  &:hover {
    box-shadow: $b-boxShadow;
    z-index: $z-filter-hover; }
  &:last-child {
    .filter-window {
      left: auto;
      right: 0;
      transform: scale(0.5) {
        origin: 75% 100%; } } } }

// -------------------------------------
//   Modifiers
// -------------------------------------

.filter--wide {
  .filter-window {
    width: ($b-space * 7); } }

// -------------------------------------
//   States
// -------------------------------------

.filter.is-enabled {
  background: white;
  box-shadow: rgba(black, 0.125) 0 5px 5px 0;
  padding-top: ($b-space * 0.6);
  z-index: $z-filter-active;
  .filter-icon {
    opacity: 1; }
  .filter-label {
    color: darken($c-blue, 10%); }
  .filter-summary {
    opacity: 1; } }

.filter.is-expanded {
  &:last-child {
    .filter-window {
      transform: scale(1); } }
  .filter-window {
    opacity: 1;
    transform: translateX(-50%) scale(1);
    visibility: visible; } }

// -------------------------------------
//   Context
// -------------------------------------


// -------------------------------------
//   Submodules
// -------------------------------------

.filter-icon {
  display: block;
  margin: 0 auto ($b-space * 0.125);
  opacity: 0.5; }

.filter-label {
  color: lighten($c-blue, 5%);
  font: {
    size: ($t-fontSize * 0.55);
    weight: 500; }
  letter-spacing: 0.125em;
  margin-top: ($b-space * 0.125);
  text-transform: uppercase; }

.filter-params,
.filter-reset {
  margin-top: ($b-space * 0.35);
  transition: opacity 500ms $b-transition; }

.filter-params {
  display: inline-block;
  text-align: left; }

.filter-reset {
  align-items: center;
  border: $b-border {
    radius: 100px; }
  color: $c-gray;
  display: flex;
  flex-flow: row nowrap;
  font-size: $t-fontSize-xs;
  margin: {
    left: auto;
    right: auto; }
  max-width: ($b-space * 1.85);
  padding: ($b-space * 0.125) ($b-space * 0.3) ($b-space * 0.125) ($b-space * 0.15);
  &:hover, &:focus {
    background: $c-blue;
    border-color: $c-blue;
    color: white;
    svg {
      fill: white; } }
  svg {
    display: inline-block;
    fill: $c-gray;
    height: 16px;
    margin-right: ($b-space * 0.125);
    width: 16px; } }

.filter-summary {
  font: {
    size: $t-fontSize-xxs;
    style: italic; }
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: ($b-space * 1.75);
  transition: all 200ms $b-transition; }

.filter-window {
  background: white;
  bottom: 0;
  box-shadow: $b-boxShadow;
  height: auto;
  left: 50%;
  opacity: 0;
  padding: ($b-space * 0.5);
  position: absolute;
  transform: translateX(-50%) scale(0.5) {
    origin: 50% 100%; }
  transition: all 200ms $b-transition;
  visibility: hidden;
  width: auto;
  z-index: $z-filter;
  width: ($b-space * 5.25); }
