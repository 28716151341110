// *************************************
//
//   Checkbox
//   -> For labels around checkboxes
//
// *************************************

.checkbox {
  display: block;
  font-size: $t-fontSize-xs;
  margin-bottom: ($b-space * 0.125);
  input {
    margin-right: ($b-space * 0.125); } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
