// *************************************
//
//   Statement
//   -> Data explorer selected statement
//
// *************************************

.statement {
  padding: 5vh $b-space;
  position: relative;
  text-align: center;
  width: 100%; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.statement-label {
  font-size: $t-fontSize-xs;
  letter-spacing: 0.125em;
  text-transform: uppercase; }

.statement-legend {
  bottom: ($b-space * 0.5);
  position: absolute;
  right: $b-space;
  text-align: right;
  p {
    font-size: $t-fontSize-xs;
    margin: 0;
    &::after {
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: ($b-space * 0.25);
      margin-left: ($b-space * 0.125);
      position: relative;
      top: 1px;
      transition: background 500ms $b-transition;
      width: ($b-space * 0.25); }
    &:first-child::after {
      background: $c-orange; }
    &:last-child {
      display: none;
      &::after {
        background: $c-blue; } } } }

.statement-legend.is-duo {
  p:last-child {
    display: block; } }

.statement-tutorial {
  border: $b-border {
    radius: $b-borderRadius; }
  color: $c-gray-dark;
  font-size: $t-fontSize-xs;
  left: $b-space;
  padding: ($b-space * 0.125) ($b-space * 0.25);
  position: absolute;
  top: ($b-space * 0.75);
  transition: all 200ms $b-transition;
  &:hover {
    border-color: lighten($c-blue, 20%);
    color: $c-blue; } }

.statement-warning {
  background: lighten($c-red, 42%);
  border-radius: $b-borderRadius;
  bottom: ($b-space * 0.5);
  color: $c-red;
  display: none;
  left: $b-space;
  line-height: 1.25;
  padding: ($b-space * 0.1) ($b-space * 0.25) ($b-space * 0.25);
  position: absolute;
  text-align: left;
  width: ($b-space * 6);
  p, strong {
    margin: 0; }
  p {
    font-size: ($t-fontSize-xxs * 1.2); }
  strong {
    font-size: $t-fontSize-xs; } }

.statement-warning.is-shown {
  display: block; }
