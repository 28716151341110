// *************************************
//
//   Base
//   -> Tag-level settings
//
// *************************************

*, *::before, *::after {
  box-sizing: border-box; }

html {
  -webkit-font-smoothing: antialiased;
  background: white;
  color: $c-gray;
  font-family: $t-fontFamily;
  font-size: $t-fontSize-s;
  line-height: $t-lineHeight;
  @include respond-to($g-s) {
    font-size: $t-fontSize; } }

body {
  font-size: 100%; }

// -------------------------------------
//   Block Content
// -------------------------------------

p {
  margin: {
    bottom: ($b-space * 0.5);
    top: 0; } }

ol, ul {
  margin: {
    bottom: ($b-space * 0.75);
    top: 0; }
  padding: 0; }

li {
  list-style: none;
  margin: {
    bottom: ($b-space * 0.5);
    top: 0; }
  font-size: $t-fontSize-s;
  a {
    color: $c-gray-dark; } }

// -------------------------------------
//   Inline Content
// -------------------------------------

// ----- Links ----- //

a {
  color: $c-blue;
  text-decoration: none;
  &:hover,
  &:focus {
    color: lighten($c-blue, 10%); } }

// ----- Images ----- //

img {
  height: auto;
  max-width: 100%; }
