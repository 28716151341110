// *************************************
//
//   Button
//   -> Stylized actions
//
// *************************************

.btn {
  background: $c-blue;
  border-radius: $b-borderRadius;
  color: white;
  display: inline-block;
  font-size: ($t-fontSize-s * 0.85);
  padding: ($b-space * 0.125) ($b-space * 0.5);
  &:hover, &:focus {
    background: darken($c-blue, 10%);
    color: white; } }

// -------------------------------------
//   Modifiers
// -------------------------------------

.btn--outline {
  background: transparent;
  border: $b-border;
  color: $c-blue;
  &:hover, &:focus {
    background: transparent;
    border-color: $c-blue;
    color: darken($c-blue, 10%); } }

.btn--s {
  font-size: $t-fontSize-xs;
  padding: ($b-space * 0.05) ($b-space * 0.25); }

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
