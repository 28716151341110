// *************************************
//
//   Lead
//   -> Long-form content intro
//
// *************************************

.lead {
  color: $c-gray-dark;
  font: {
    family: $t-fontFamily-heading;
    size: ($t-fontSize * 1.25); }
  line-height: 1.25;
  margin-bottom: $b-space;
  @include respond-to($g-s) {
    font-size: $t-fontSize-l; } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
