// *************************************
//
//   Title
//   -> Homepage hero placement
//
// *************************************

.title {
  background: radial-gradient(circle at center, white 50%, lighten($c-gray-light, 5%) 100%);
  height: 90vh;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  @include respond-to($g-s) {
    min-height: 800px; }
  @include respond-to($g-m) {
    height: 100vh; }
  &::before {
    background: linear-gradient(to bottom, rgba(white, 0), rgba(white, 1));
    bottom: 0;
    content: '';
    height: 50vh;
    left: 0;
    position: absolute;
    right: 0; }
  p {
    font-size: $t-fontSize-xs;
    margin: 0 0 ($b-space * 0.25);
    @include respond-to($g-s) {
      font-size: ($t-fontSize-s * 0.75);
      margin: 0; }
    @include respond-to($g-m) {
      font-size: $t-fontSize-s; } }
  span {
    color: darken($c-gray-light, 15%); } }

// -------------------------------------
//   States
// -------------------------------------

.title.is-shown {
  .title-content {
    animation: fadeIn 1s 2.25s 1 $b-transition {
      fill-mode: forwards; } }
  .title-loader {
    opacity: 0;
    visibility: hidden; } }

// -------------------------------------
//   Submodules
// -------------------------------------

.title-content {
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  margin: auto;
  opacity: 0;
  right: 0;
  position: absolute;
  top: 0;
  width: ($b-space * 5);
  z-index: $z-title-content;
  @include respond-to($g-s) {
    width: ($b-space * 6.5); }
  @include respond-to($g-m) {
    width: ($b-space * 8); }
  p {
    display: none;
    @include respond-to($g-s) {
      display: block; } } }

.title-hint {
  border: {
    bottom: 2px solid darken($c-gray-light, 10%);
    right: 2px solid darken($c-gray-light, 10%); }
  bottom: ($b-space * 0.5);
  height: ($b-space * 0.5);
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  transform: rotate(45deg);
  width: ($b-space * 0.5);
  z-index: $z-title-hint; }

.title-loader {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  transition: all 300ms $b-transition; }
