// *************************************
//
//   Tools
//   -> Global utilities
//
// *************************************

// -------------------------------------
//   Background
// -------------------------------------

// -------------------------------------
//   Display
// -------------------------------------

.db {
  display: block; }

// -------------------------------------
//   Floats
// -------------------------------------

.fl {
  float: left; }
.fr {
  float: right; }

// -------------------------------------
//   Group
// -------------------------------------

.group {
  &::after,
  &::before {
    clear: both;
    content: '';
    display: table; } }

// -------------------------------------
//   Margin
// -------------------------------------

// ----- All ----- //

// ----- Bottom ----- //

.mb0 {
  margin-bottom: 0; }
.mb0125 {
  margin-bottom: ($b-space * 0.125); }
.mb025 {
  margin-bottom: ($b-space * 0.25); }
.mb1 {
  margin-bottom: $b-space; }

// ----- Horizontal ----- //

// ----- Left ----- //

// ----- Right ----- //

// ----- Top ----- //

.mt0 {
  margin-top: 0; }
.mt025 {
  margin-top: ($b-space * 0.25); }
.mt1 {
  margin-top: $b-space; }
.mt15 {
  margin-top: ($b-space * 1.5); }

// -------------------------------------
//   Padding
// -------------------------------------

// ----- All ----- //

// ----- Bottom ----- //

// ----- Horizontal ----- //

.px0 {
  padding: {
    left: 0;
    right: 0; } }

// ----- Left ----- //

// ----- Right ----- //

// ----- Top ----- //

// -------------------------------------
//   Positioning
// -------------------------------------

// -------------------------------------
//   Rounding
// -------------------------------------

// -------------------------------------
//   Text
// -------------------------------------

// ----- Alignment ----- //

.tac {
  text-align: center; }
.tar {
  text-align: right; }

// ----- Color ----- //

// ----- Family ----- //

// ----- Size ----- //

.tsxs {
  font-size: $t-fontSize-xs; }
.tss {
  font-size: $t-fontSize-s; }

// ----- Style ----- //

// ----- Transform ----- //

// ----- Weight ----- //
