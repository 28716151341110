// *************************************
//
//   Divider
//   -> Interactive horizontal rule
//
// *************************************

.divider {
  border: none {
    top: $b-border; }
  text-align: center; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// ----- Theme ----- //

.divider--label {
  border: none;
  display: block;
  font: {
    family: $t-fontFamily-heading;
    size: $t-fontSize-xs; }
  letter-spacing: 0.125;
  margin: 0 auto;
  position: relative;
  text-transform: uppercase;
  width: $b-space;
  &::after,
  &::before {
    border-top: $b-border;
    content: "";
    position: absolute;
    top: ($b-space * 0.15); }
  &::after {
    left: $b-space;
    right: ($b-space * -1);
    @include respond-to($g-s) {
      right: ($b-space * -2); }
    @include respond-to($g-m) {
      right: ($b-space * -3); } }
  &::before {
    left: ($b-space * -1);
    right: $b-space;
    @include respond-to($g-s) {
      left: ($b-space * -2); }
    @include respond-to($g-m) {
      left: ($b-space * -3); } } }

.divider--legend {
  margin-bottom: ($b-space * 0.5);
  width: calc(100% - #{$b-space * 2});
  &::after {
    top: 15px !important; }
  .divider-content {
    &:first-child,
    &:last-child {
      position: absolute;
      top: 6px; }
    &:first-child {
      left: 0; }
    &:last-child {
      right: 0; } } }

.divider--links {
  border: none;
  font-size: $t-fontSize-s;
  position: relative;
  text-align: center;
  &::after {
    border-top: $b-border;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 12px; }
  a {
    margin: 0 ($b-space * 0.125); } }

// ----- Sizes ----- //

.divider--s {
  margin: ($b-space * 0.25) 0;
  width: $b-space; }

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.divider-content {
  background: white;
  box-shadow: white 0 0 0 10px;
  display: inline-block;
  position: relative;
  z-index: $z-divider-content; }
