// *************************************
//
//   Brand
//   -> Logo positioning
//
// *************************************

.brand {
  display: flex;
  height: 22px;
  left: ($b-space * 0.5);
  position: fixed;
  top: ($b-space * 0.475);
  transition: all 500ms $b-transition;
  width: 22px;
  z-index: $z-brand;
  @include respond-to($g-s) {
    left: $b-space; }
  @include respond-to($g-m) {
    height: 28px;
    opacity: 0.4;
    top: ($b-space * 0.725);
    width: 28px; }
  svg {
    display: block;
    height: auto;
    width: 100%; }
  .explorer & {
    opacity: 1; } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

.brand.is-shown {
  opacity: 1; }

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
