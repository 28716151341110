// *************************************
//
//   Navigation
//   -> State changing links
//
// *************************************

.navigation {
  align-items: center;
  display: flex;
  height: ($b-space * 1.5);
  justify-content: space-between;
  padding: 0 ($b-space * 0.4) 0 ($b-space * 1.25);
  position: fixed;
  top: 0;
  transition: all 500ms $b-transition;
  width: 100%;
  z-index: $z-navigation;
  @include respond-to($g-s) {
    padding-left: ($b-space * 1.75); }
  @include respond-to($g-m) {
    background: white;
    box-shadow: none;
    height: ($b-space * 2.125);
    padding: 0 $b-space 0 ($b-space * 2.05);
    transform: translateY(-100%); } }

// -------------------------------------
//   Modifiers
// -------------------------------------

.navigation--shown {
  border-bottom: $b-border;
  flex-shrink: 0;
  position: relative;
  transform: translateY(0%);
  .navigation-title {
    opacity: 1; } }

// -------------------------------------
//   States
// -------------------------------------

.navigation.is-shown {
  background: white;
  box-shadow: $b-boxShadow;
  transform: translateY(0%);
  .navigation-title {
    opacity: 1; } }

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.navigation-link {
  display: none;
  @include respond-to($g-m) {
    color: $c-gray;
    display: inline-block;
    font-size: $t-fontSize-xs;
    letter-spacing: 0.125em;
    margin-left: ($b-space * 0.75);
    text-transform: uppercase; } }

.navigation-link.is-active {
  color: $c-gray-dark;
  font-weight: bold; }

.navigation-share {
  border: $b-border {
    radius: $b-borderRadius; }
  display: inline-block;
  font-size: $t-fontSize-xs;
  letter-spacing: 0.125em;
  margin-left: ($b-space * 0.75);
  padding: ($b-space * 0.125) ($b-space * 0.25);
  text-transform: uppercase;
  @include respond-to($g-s) {
    padding: ($b-space * 0.125) ($b-space * 0.5); } }

.navigation-title {
  opacity: 0;
  transition: all 500ms $b-transition; }
