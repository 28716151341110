// *************************************
//
//   Item
//   -> Statements list item in modal
//
// *************************************

.item {
  display: block;
  margin: 0 0 $b-space;
  padding: ($b-space * 0.1) 0 0;
  position: relative;
  &:last-of-type {
    margin: 0; }
  a {
    display: block;
    padding: 0 $b-space;
    &:before {
      align-items: center;
      background: $c-blue;
      border-radius: 50%;
      color: white;
      content: attr(data-delimiter);
      display: flex;
      font: {
        size: $t-fontSize-xs;
        weight: bold; }
      height: ($b-space * 0.75);
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: ($b-space * 0.75); } }
  #statements-2014 & {
    &:nth-of-type(21) {
      margin-bottom: $b-space * 1.5; } } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
