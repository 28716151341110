// *************************************
//
//   Heading
//   -> Various headings
//
// *************************************

.heading {
  color: $c-gray-dark;
  font: {
    family: $t-fontFamily-heading;
    weight: 300; }
  line-height: 1.3;
  margin: ($b-space * 1.5) 0 ($b-space * 0.25); }

// -------------------------------------
//   Modifiers
// -------------------------------------

// ----- Position ----- //

.heading--fixed {
  display: none;
  @include respond-to($g-s) {
    display: block;
    font: {
      family: $t-fontFamily;
      size: 0.75rem;
      weight: 500; }
    left: ($b-space * 2.5);
    line-height: 1;
    margin: auto;
    position: fixed;
    text-align: center;
    top: ($b-space * 1.25); }
  @include respond-to($g-l) {
    left: 0;
    right: 0; } }

.heading--window {
  font: {
    family: $t-fontFamily;
    size: 1rem;
    weight: 500; }
  left: ($b-space * 2.5);
  margin: auto;
  position: fixed;
  text-align: center;
  top: ($b-space * 1.25);
  @include respond-to($g-l) {
    right: 0; } }

// ----- Sizes ----- //

.heading--s {
  font: {
    size: $t-fontSize-s;
    weight: 500; }
  margin: 0;
  @include respond-to($g-s) {
    font-size: $t-fontSize; } }

.heading--m {
  font-size: $t-fontSize;
  margin-top: ($b-space * 0.25);
  @include respond-to($g-s) {
    font-size: $t-fontSize-m; } }

.heading--l {
  font-size: ($t-fontSize * 1.25);
  margin-top: ($b-space * 0.25);
  @include respond-to($g-s) {
    font-size: $t-fontSize-l; } }

.heading--xl {
  font-size: ($t-fontSize-l * 1.25);
  margin-top: 0;
  @include respond-to($g-s) {
    font-size: $t-fontSize-xl; } }

// ----- Theme ----- //

.heading--sans {
  font: {
    family: $t-fontFamily;
    weight: 500; } }

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
