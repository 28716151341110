// *************************************
//
//   Halo
//   -> Homepage here chart styling
//
// *************************************

.halo {
  display: flex;
  flex-flow: column nowrap;
  height: 90vh;
  justify-content: center;
  margin: auto;
  max-width: 400px;
  position: relative;
  width: 95vw;
  z-index: $z-title-halo;
  @include respond-to($g-s) {
    height: 90vh;
    max-width: $b-maxWidth;
    min-height: 800px; }
  @include respond-to($g-m) {
    height: 100vh; }
  svg {
    display: block; }
  text {
    user-select: none; } }

// -------------------------------------
//   Submodules
// -------------------------------------

.halo-axes-arc {
  fill: none;
  opacity: 0;
  stroke: $c-gray-light;
  stroke-dasharray: 5 5;
  stroke-width: 1px;
  @for $i from 1 to 4 {
    &:nth-of-type(#{$i}) {
      animation: scaleIn 2.5s #{$i * 200 + 1250}ms 1 $b-transition {
        fill-mode: forwards; } } } }

.halo-axes-label {
  dominant-baseline: middle;
  fill: darken($c-gray-light, 20%);
  font-size: 7px;
  opacity: 0;
  text-anchor: middle;
  text-transform: uppercase;
  @media (min-width: 800px) {
    font-size: 11px; }
  @for $i from 0 to 3 {
    &:nth-of-type(3n + #{$i}) {
      animation: scaleInwards 2.5s #{$i * 200 + 750}ms 1 $b-transition {
        fill-mode: forwards; } } } }

.halo-axes-text {
  dominant-baseline: middle;
  fill: $c-gray;
  font-size: 6px;
  letter-spacing: 0.1em;
  text-anchor: middle;
  text-transform: uppercase;
  @include respond-to($g-s) {
    font-size: 8px; }
  @include respond-to($g-m) {
    font-size: 10px; } }

.halo-axes-title {
  dominant-baseline: middle;
  fill: darken($c-gray-light, 15%);
  font-size: 7px;
  letter-spacing: 0.1em;
  text-anchor: middle;
  text-transform: uppercase;
  @media (min-width: 800px) {
    font-size: 11px; } }

.halo-pie-segment {
  opacity: 0;
  @for $i from 0 to 3 {
    &:nth-of-type(3n + #{$i}) {
      animation: scaleIn 2.5s #{$i * 250}ms 1 $b-transition {
        fill-mode: forwards; } } } }

.halo-pie-segment-path {
  fill: darken($c-gray-lightest, 3%); }

.halo-plot-path {
  animation: fadeIn 3s 3s 1 $b-transition {
    fill-mode: forwards; }
  fill: none;
  opacity: 0;
  stroke: $c-orange;
  stroke-width: 1px;
  @include respond-to($g-s) {
    stroke-width: 2px; } }

.halo-plot-point {
  fill: white;
  opacity: 0;
  stroke: $c-orange;
  stroke-width: 1px;
  transform-origin: 50% 50%;
  @include respond-to($g-s) {
    stroke-width: 2px; }
  @for $i from 0 to 3 {
    &:nth-of-type(3n + #{$i}) {
      animation: popIn 1s #{$i * 250 + 2250}ms 1 $b-transition {
        fill-mode: forwards; } } } }
