// *************************************
//
//   Columns
//   -> Statement layout
//
// *************************************

.columns {
  column-count: 2; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------
