// *************************************
//
//   Explorer
//   -> Flexbox wrapper for the explorer
//
// *************************************

.explorer {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: 100vh;
  justify-content: space-between;
  overflow: hidden; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.explorer-chart {
  flex-grow: 1;
  margin-bottom: $b-space;
  overflow: hidden;
  position: relative;
  width: calc(100% - #{$b-space * 2});
  &::after, &::before {
    box-shadow: $b-boxShadow;
    border-radius: 100px;
    bottom: ($b-space * 0.25);
    content: "";
    position: absolute;
    top: ($b-space * 0.125);
    width: $b-space; }
  &::after {
    left: 100%; }
  &::before {
    right: 100%; } }
