// *************************************
//
//   Row
//   -> Width-spanning blocks
//
// *************************************

.row {
  overflow: hidden;
  padding: 0 ($b-space * 0.5);
  @include respond-to($g-s) {
    padding: 0 $b-space; } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// ----- Sizes ----- //

.row--l {
  padding: 0 $b-space;
  @include respond-to($g-s) {
    padding: 0 ($b-space * 2); } }
