// *************************************
//
//   Modal
//   -> Statement selections
//
// *************************************

.modal {
  display: flex;
  height: 100vh;
  left: 0;
  opacity: 0;
  overflow: {
    x: hidden;
    y: scroll; }
  padding: $b-space;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100vw;
  z-index: $z-modal; }

// -------------------------------------
//   Modifiers
// -------------------------------------

.modal--s {
  .modal-window {
    overflow: hidden !important;
    text-align: center;
    transform: translateY($b-space);
    width: ($b-maxWidth * 0.5); } }

.modal--video {
  .modal-window {
    overflow: hidden !important;
    transform: translateY($b-space);
    width: $b-maxWidth; }
  .well {
    margin: 0;
    position: relative;
    padding-bottom: 55.5%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } } }

// -------------------------------------
//   States
// -------------------------------------

.modal.is-shown {
  opacity: 1;
  visibility: visible;
  .modal-overlay {
    opacity: 1; }
  .modal-window {
    opacity: 1;
    transform: translateY(0); } }

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.modal-overlay {
  background: radial-gradient(ellipse at center, rgba(black, 0.125) 25%, rgba(black, 0.35) 90%, rgba(black, 0.5) 100%);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 500ms $b-transition;
  width: 100vw; }

.modal-window {
  background: white;
  border-radius: ($b-borderRadius * 2);
  box-shadow: $b-boxShadow, $b-boxShadow;
  margin: auto;
  opacity: 0;
  overflow: {
    x: hidden;
    y: scroll !important; }
  position: relative;
  transform: translateY(#{$b-space * 3}) {
    origin: 50% 0%; }
  transition: all 500ms $b-transition;
  width: ($b-maxWidth * 1.5);
  z-index: $z-modal + 10; }
