// *************************************
//
//   Well
//   -> Vertical spacing
//
// *************************************

.well {
  margin-bottom: $b-space;
  margin-top: $b-space; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// ----- Sizes ----- //

.well--s {
  margin-bottom: ($b-space * 0.5);
  margin-top: ($b-space * 0.5); }

.well--l {
  margin-bottom: ($b-space * 2);
  margin-top: ($b-space * 2); }

.well--xl {
  margin-bottom: $b-space;
  margin-top: ($b-space * 3); }
