// *************************************
//
//   Input
//   -> Various text-based inputs
//
// *************************************

.input {
  -webkit-appearance: none;
  background: white;
  border: $b-border {
    radius: $b-borderRadius; }
  display: block;
  font-size: ($t-fontSize * 0.75);
  padding: ($b-space * 0.125) ($b-space * 0.25);
  width: 100%; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
