// *************************************
//
//   Builder
//   -> Explorer profile builder
//
// *************************************

.builder {
  display: flex;
  flex: {
    flow: row nowrap;
    shrink: 0; }
  height: ($b-space * 2.5);
  width: 100%; }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.builder-filters {
  align-items: flex-end;
  display: flex;
  flex: {
    flow: row nowrap;
    grow: 1; } }

.builder-intro {
  border: {
    right: $b-border;
    top: $b-border; }
  display: flex;
  flex: {
    flow: column nowrap;
    shrink: 0; }
  justify-content: center;
  padding: 0 ($b-space * 0.5) 0 $b-space;
  position: relative;
  width: 190px;
  p {
    font-size: $t-fontSize-xs;
    line-height: 1.25;
    margin: ($b-space * 0.125) 0 0; } }

.builder-reset {
  align-items: center;
  border: $b-border {
    radius: 100px; }
  color: $c-gray !important;
  display: flex;
  flex-flow: row nowrap;
  font-size: $t-fontSize-xxs;
  margin-top: ($b-space * 0.25);
  max-width: ($b-space * 1.75);
  padding: ($b-space * 0.125) ($b-space * 0.2) ($b-space * 0.125) ($b-space * 0.15);
  &:hover {
    background: $c-blue;
    border-color: $c-blue;
    color: white !important;
    svg {
      fill: white; } }
  svg {
    display: inline-block;
    fill: $c-gray;
    height: 12px;
    margin-right: ($b-space * 0.05);
    width: 12px; } }
