// *************************************
//
//   Tip
//   -> Bivariate chart tooltip
//
// *************************************

.tip {
  align-items: center;
  background: white;
  box-shadow: $b-boxShadow;
  display: none;
  flex-flow: column nowrap;
  font-size: $t-fontSize-xxs;
  height: ($b-space * 2.5);
  justify-content: center;
  padding: 0 ($b-space * 0.25);
  pointer-events: none !important;
  text-align: center;
  width: ($b-space * 4.5);
  z-index: $z-tooltip;
  @include respond-to($g-s) {
    display: flex;
    transform: translateY(50%); }
  @include respond-to($g-m) {
    height: ($b-space * 2.5);
    transform: translateY(75%);
    width: ($b-space  * 4.75); }
  &::after {
    background: white;
    box-shadow: rgba(black, 0.125) 5px 5px 10px 0;
    content: '';
    height: ($b-space * 0.5);
    left: calc(50% - #{$b-space * 0.25});
    position: absolute;
    top: calc(100% - #{$b-space * 0.25});
    transform: rotate(45deg);
    width: ($b-space * 0.5); }
  p {
    margin: 0; } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

.tip-everyone,
.tip-profile {
  display: inline-block;
  width: 50%;
  span {
    display: block;
    font-size: $t-fontSize-m; } }

.tip-everyone span {
  color: $c-orange; }

.tip-profile span {
  color: $c-blue; }

.tip-statistics {
  margin-bottom: ($b-space * 0.125);
  padding: 0 ($b-space * 0.5);
  width: 100%; }
