// *************************************
//
//   Card
//   -> Miscellaneous features
//
// *************************************

.card {
  background: darken(white, 4%);
  border-radius: $b-borderRadius;
  font-size: ($t-fontSize * 0.75);
  line-height: 1.4;
  padding: ($b-space * 0.5);
  @include respond-to($g-m) {
    display: none; }
  p {
    margin-bottom: ($b-space * 0.25); } }

// -------------------------------------
//   Modifiers
// -------------------------------------

// .mv-module--modifier

// -------------------------------------
//   States
// -------------------------------------

// .mv-module.is-state

// -------------------------------------
//   Context
// -------------------------------------

// .has-mv-module

// -------------------------------------
//   Submodules
// -------------------------------------

// .mv-module-submodules
