// *************************************
//
//   Helpers
//   -> Functions, Mixins, Animations
//
// *************************************

// -------------------------------------
//   Functions
// -------------------------------------

// ----- Shade ----- //
// -> Slightly darken a color
//    via: http://sass-guidelin.es/#lightening-and-darkening-colors (modified)
//
// $color - the color to shade
// $percentage - the percentage of black to add to `$color`

@function shade($color, $percentage) {
  @return mix(black, saturate($color, $percentage), $percentage); }

// ----- Tint ----- //
// -> Slightly lighten a color
//    Via: http://sass-guidelin.es/#lightening-and-darkening-colors (modified)
//
// $color - the color to tint
// $percentage - the percentage of white to add to `$color`

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage); }

// -------------------------------------
//   Mixins
// -------------------------------------

// ----- Respond-to ----- //
// -> Generates a media query
//
// $val - the breakpoint size
// $query - the type of query ('min-width', 'max-width')
// $media - the media type ('screen', 'print', etc.)
// @content - the generated content within the mixin

@mixin respond-to($val, $query: min-width, $media: screen) {
  @media #{$media} and ($query: $val) {
    @content; } }

// -------------------------------------
//   Animations
// -------------------------------------

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0.5); }
  75% {
    transform: scale(1.25); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.5); }
  to {
    opacity: 1;
    transform: scale(1); } }

@keyframes scaleInwards {
  from {
    opacity: 0;
    transform: scale(1.25); }
  to {
    opacity: 1;
    transform: scale(1); } }
