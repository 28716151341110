// *************************************
//
//   Config
//   -> Fonts, Variables
//
// *************************************

// -------------------------------------
//   Colors
// -------------------------------------

// ----- Palette ----- //

$c-blue: #869ba1;
$c-navy: rgba(#0C3642, 0.5);
$c-orange: #daa080;
$c-red: #c55947;

// ----- Grayscale ----- //

$c-gray-lightest: #fcfcfc;
$c-gray-light: #dddddd;
$c-gray: #6a6a6a;
$c-gray-dark: #444444;

// -------------------------------------
//   Base
// -------------------------------------

// ----- Borders & Box Shadow ----- //

$b-borderRadius: 2px;
$b-borderStyle: solid;
$b-borderWidth: 1px;
$b-border: $b-borderWidth $b-borderStyle $c-gray-light;
$b-boxShadow: rgba($c-gray-dark, 0.25) 0 5px 15px 0;

// ----- Layout ----- //

$b-space: 40px;
$b-maxWidth: 724px;

// ----- Transitions ----- //

$b-transition: cubic-bezier(.55,0,.1,1);

// ----- Layering ----- //

$z-brand: 100;
$z-divider-content: 10;
$z-filter: 30;
$z-filter-active: 10;
$z-filter-hover: 20;
$z-legend-labels: 10;
$z-modal: 200;
$z-navigation: 30;
$z-title-content: 20;
$z-title-halo: 20;
$z-title-hint: 20;
$z-tooltip: 20;

// -------------------------------------
//   Typography
// -------------------------------------

// ----- Family ----- //

$t-fontFamily: 'Whitney SSm A', 'Whitney SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$t-fontFamily-heading: 'Mercury Display A', 'Mercury Display B', Georgia, 'Times New Roman', Times, serif;

// ----- Line Height ----- //

$t-lineHeight: 1.5;

// ----- Size ----- //

$t-fontSize-xxs: 0.5rem;
$t-fontSize-xs: 0.667rem;
$t-fontSize-s: 16px;
$t-fontSize: 18px;
$t-fontSize-m: 1.25rem;
$t-fontSize-l: 1.625rem;
$t-fontSize-xl: 3.5rem;

// Headings

$t-heading-fontSize-s: 24px;
$t-heading-fontSize: 36px;
$t-heading-fontSize-l: 90px;

// -------------------------------------
//   Modules
// -------------------------------------

// ----- Button ----- //

$button-size: ($b-space * 0.85);

// ----- Grid ----- //

// Breakpoints

$g-s: 600px;
$g-m: 1100px;
$g-l: 1330px;


// ----- Loader ----- //

$loader-size: ($b-space * 2);

// ----- Window ----- //

$window-sidebar-width: 450px;
